<!--  -->
<template>
  <div class="body">
    <el-form class="login-container" :model="form" ref="form" :rules="rules"  label-width="100px">
        <h3 class="login_title">系统登录</h3>
        <el-form-item label="登录名称" prop="username">
          <el-input v-model="form.username" placeholder="请输入登录名称"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
            <el-input v-model="form.password" type="password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button @click="submit" type="primary">登录</el-button>
        </el-form-item>
    </el-form>    
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import http from '@/tools/request'
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
   form:{
      username:'',
      password:'',     
   },  
   rules:{
      username:[
        { required:true, message:'请输入用户名称',trigger:'blur'},
        { min:2,max:10,message:'长度在2-10之间',tigger:'blur' }
    ],
      password:[
        { required:true,message:'请输入密码',trigger:'blur'},
        { min:6,max:10,message:'长度在6-10之间',tigger:'blur' }
      ]
   }
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    submit(){      
      this.$refs.form.validate((valid) => {
        if (valid) {//校验成功后valid为true,执行隐藏  
          http.post("userinfor/checklogin", this.form) //显示第一页，每页10条数据                
          .then(res=>{ 
            console.log(res)                 
            if(res.data.code==201){         
                Cookie.set('token',res.data.token,{ expires: 7 }) //设置token过期时间
                Cookie.set('token',res.data.data.token,{ expires: 7 }) //设置token过期时间
                Cookie.set('ID',res.data.data.ID,{ expires: 7 })
                Cookie.set('username',res.data.data.username,{ expires: 7 })  
                Cookie.set('adminnum',res.data.data.adminnum,{ expires: 7 }) 
                if(res.data.data.unitid)
                  Cookie.set('unitid',res.data.data.unitid,{ expires: 7 }) 
                else
                  Cookie.set('unitid',0,{ expires: 7 })
                if(res.data.data.unitname)
                  Cookie.set('unitname',res.data.data.unitname,{ expires: 7 }) 
                else
                  Cookie.set('unitname','超级管理员',{ expires: 7 }) 
                if(res.data.data.showname)
                  Cookie.set('showname',res.data.data.showname,{ expires: 7 }) 
                else
                  Cookie.set('showname','超级管理员',{ expires: 7 }) 

                console.log(res.data)
                
                this.$message({
                        message:'登录成功,正在跳转......',
                        type: 'success'
                  })  
              //跳转到首页
              this.$router.push('/home')                 
            }
            else
            {
                  this.$message({
                        message:'登录失败，用户名或密码不正确！',
                        type: 'error'
                  })
                  // this.$refs['form'].resetFields() //清空输入内容 
                  this.form.username=''
                  this.form.password='' 
            }                   
          })
        }
      })  
         },
    getUserIP (onNewIP) {
        let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
        let pc = new MyPeerConnection({
          iceServers: []
        })
        let noop = () => {          
        }
        let localIPs = {}
        let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
        let iterateIP = (ip) => {
          if (!localIPs[ip]) onNewIP(ip)
          localIPs[ip] = true
        }
        pc.createDataChannel('')
        pc.createOffer().then((sdp) => {
          sdp.sdp.split('\n').forEach(function (line) {
            if (line.indexOf('candidate') < 0) return
            line.match(ipRegex).forEach(iterateIP)
          })
          pc.setLocalDescription(sdp, noop, noop)
        }).catch((reason) => {
        })
        pc.onicecandidate = (ice) => {
          if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
          ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
        }
      },
    
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  console.log('跳转到了logo执行了')
  


},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="less" scoped>
.body{
  background: url(../assets/images/logo_back.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height:calc(100vh); 
  display: flex;
  align-items: center;//子组件居中
}
.login-container{
    width: 350px;
    height: 250px;
    border:1px solid #eaeaea;
    border-radius: 15px;
    margin:180px auto;
    padding: 35px 35px 15px 35px;
    box-shadow:0 0 5px #cac6c6 ;
    background-color: #FFF;
    .login_title{
        text-align: center;
        margin-bottom: 40px;
        color: #505458;      
    }
    .el-button {
        margin:10px auto 10px 50px;
    }
}

</style>