<!--  -->
<template>
    <div class='header-container'>
        <div class="l-content">
            <el-button style="margin-right: 20px;" @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
            <!-- 面包屑 -->
            <el-breadcrumb separator="/" >
                <el-breadcrumb-item v-for="item in tags" :key="item.path" 
                :to="{path:item.path}">{{item.label}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="r-content">
            <el-dropdown   @command="handleCommand">
                <span class="el-dropdown-link">
                    <el-button icon="el-icon-user" size="mini" circle></el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <!-- disabled不可用，divided为下划分隔线 -->
                    <el-dropdown-item  command="userset" >个人中心</el-dropdown-item>
                    <el-dropdown-item  command="editpassword"   divided>修改密码</el-dropdown-item>
                    <el-dropdown-item  command="exitlogin" divided>退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
import {mapState} from 'vuex'  //使用辅助函数调用vues中的state数据
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {
        ...mapState({
        tags:state=>state.tab.tabsList  //返回一下对象，需要使用扩展运算符进行解构
        })
    },
    //监控data中的数据变化
    watch: {
        // $route(to,from){
        //  this.breadcrumbList=to.matched;
        // }
    },
    //方法集合
    methods: {
        handleMenu(){
           this.$store.commit('collapseMenu') //通过commit方法调用collapsmenu方法
           //console.log('点击了菜单收起来');
        },
        handleCommand(command) {
          if(command==='exitlogin'){
            this.$confirm('确定进行登出操作是否继续?', '操作提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                     //清除cokie 跳转到login
                     Cookie.remove('token')
                     Cookie.remove('ID')
                     Cookie.remove('username')
                     Cookie.remove('unitid')
                    this.$router.push('/login')   
                 })                      
            .catch(() => {
                this.$message({type: 'info',message: '已取消'});  
            })             
          }else if(command==='userset' &&  this.$route.path!=='/userinfor'){
            //console.log(this.$route.path);
            this.$router.push('/userinfor')
          }else if(command==='editpassword' &&  this.$route.path!=='/editpassword'){
            this.$router.push('/editpassword')
          }

        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        // this.tags=this.$route.matched;
        // console.log(this.breadcrumbList);
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="less" scoped>
.header-container {
    padding: 0 20px;
    background-color: #333;
    height: 60px;
    display: flex;
    justify-content: space-between; //分在两侧对齐
    align-items: center; //垂直居中

    .text {
        color: #FFF;
        font-size: 14px;
        margin-left: 10px;
    }
    .l-content{
        display: flex;
        align-items: center; //另一轴的居中
        /deep/ .el-breadcrumb__item{ // /deep/样式穿刺
          .el-breadcrumb__inner{  
                font-weight:normal ;
                &.is-link{
                    color:#666;
                }
            }
            &:last-child{   //使用一个伪类 &为当前类向前退一个，即加上一下before
                .el-breadcrumb__inner{
                    color: #FFF;
                 }
            }
        }
    }
}
</style>