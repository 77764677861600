// 用来管理菜单的状态
export default{
    state:{  
        isCollapse:false, //这是控制菜单是否折叠的状态
        tabsList:[
            {
                path:'/',
                name:'home',
                label:'首页',
                icon:'s-home',
                url:'Home/Home'
            }
        ],//存放面包屑的数据
    },
    mutations:{
        //修改菜单展开和收起的方法
     collapseMenu(state){
         state.isCollapse=!state.isCollapse //将传入的参数进行取反
     },
     selectMenu(state,menuitem){  //面包屑数据处理       
       // console.log(menuitem.name)
        if(menuitem.name!=='home'){ //不是首页才增加，且要判断是否存在
          const index=state.tabsList.findIndex(item=>item.name === menuitem.name)  //如果存在则返回查找到的数据索引，不存在返回-1
          if(index===-1){
             state.tabsList.push(menuitem)
          }          
        }
     },
      closeTag(state,item){  //用来删除指定的tab数据,接收固定的两个参数
       const index=state.tabsList.findIndex(val=>val.name===item.name)
       state.tabsList.splice(index,1) //删除的位置和个数
    }
    }
    
}