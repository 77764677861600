<!--  -->
<template>
<div class='tabs'>
    <el-tag  v-for="(item,index) in tags"  :key="item.path"
      :closable="item.name!=='home'" :effect="$route.name===item.name?'dark':'plain'"
      @click="changMenu(item)" @close="handleclose(item,index)" size="small">
        {{item.label}}
    </el-tag>
</div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {mapState,mapMutations} from 'vuex'  //使用辅助函数调用vues中的state数据
//mapMutations为辅助函数的方法
export default {    
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {

};
},
//监听属性 类似于data概念
computed: {
    ...mapState({
        tags:state=>state.tab.tabsList  //返回一下对象，需要使用扩展运算符进行解构
    })
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    ...mapMutations(['closeTag']),//需要调用一下mapMutations,解构出来closeTag方法
    changMenu(item){
        console.log(item)
        this.$router.push({name:item.name})
    },
    handleclose(item,index){ //删除的逻辑
      //如果index为0则不允许删除了
      //可以通过this.$store.commit方法，也可以通过辅助函数的方法进行
      this.closeTag(item)
     const length=this.tags.length //取得索引
     //删除之后的跳转如果删除的不是高亮显示的，则不需要跳转
     if(item.name!==this.$route.name){
        return
     }
     if(index===length) //表示删除的是最后一项,就要跳转到前一个路由
     {
        this.$router.push({name:this.tags[index-1].name})
     }else //删除的是中间的则向后跳转
     {
        this.$router.push({name:this.tags[index].name})
     }

    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="less" scoped>
  .tabs{
    padding:20px;
  
    .el-tag{
        margin-right: 15px;
        cursor: pointer;
    }
  }
</style>