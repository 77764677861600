<!-- 这是主页 -->
<template>
  <div class="home">
    <el-row>
      <el-col :span="8" style="padding-right: 10px;">
        <el-card class="box-card">
          <div class="user">
            <img src="../assets/images/user.png" alt="" />
            <div class="userinfo">
              <p class="name">{{userinfor.username}}</p>
              <P class="access">{{userinfor.adminnum==2?'一般用户':userinfor.adminnum==1?'单位管理员':'系统管理员'}}</P>
            </div>
          </div>
          <div class="login-info">
            <p>上次登录时间：<span>{{ userinfor.logindate }}</span></p>
            <P>上次登录地址：<span>江苏淮安</span></P>
          </div>
        </el-card>
        
          <el-card style="margin-top: 20px;height: 460px;">
          <el-table :data="tableData" height="460px" style="width: 100%">
             <el-table-column prop="date" label="日期" width="90"></el-table-column>
            <el-table-column prop="name" label="姓名" width="80"></el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column> 
            <el-table-column v-for="(val,key) in tableLable"  :key="key" :prop="key" :label="val" />
          </el-table>
        </el-card>
       
      </el-col>
      <el-col :span="16" style="padding-left: 10px;">
        <div class="num">
            <el-card v-for="item in countData" :key="item.name" :body-style="{display:'flex',padding:0}">
                <i class="icon" :class="`el-icon-${item.icon}`" :style="{background:item.color}"></i>
                <div class="detail">                    
                    <P class="price">{{ item.value }}</P>
                    <P class="desc">{{item.name }}</P>
                </div>
            </el-card>
        </div>
        <el-card style="height: 280px;">
        <!-- 折线图 使用ref可以直接this进行访问-->
        <div ref="echarts1" style="height:280px;"></div>
        </el-card>
        <div class="graph">
          <el-card style="height: 260px;">1</el-card>
          <el-card style="height: 260px;">2</el-card>
        </div> 
      </el-col>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
//将api中的index.js中的getData进行解构
import * as  echarts from 'echarts'
import http from '@/tools/request'
import jwt from '@/tools/jwt'
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {      
       userinfor:{
            id:'1',
        },
        tableLable:{
            date:'日期',
            summary:'天气',
            temperatureC:'摄氏度'
        },
        tableData: [{
            date: '2016-05-02',
            summary: '王小虎',
            temperatureC: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            summary: '王小虎',
            temperatureC: '上海市普陀区金沙江路 1517 弄'
          }],
        countData:[
            {
                name:"设备数量",
                value:5,
                icon:"success",
                color:"#2ec7c9"
            }
            // ,{
            //     name:"今日收藏订单",
            //     value:210,
            //     icon:"star-on",
            //     color:"#ffb980"
            // },{
            //     name:"今日未支付订单",
            //     value:1234,
            //     icon:"s-goods",
            //     color:"#5ab1ef"
            // },{
            //     name:"本月支付订单",
            //     value:1210,
            //     icon:"success",
            //     color:"#2ec7c9"
            // }
        ]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const token= Cookie.get('token')
   // this.userinfor=jwt.getuserfromjwt(token)
    console.log(this.userinfor)
    console.log('token:'+token);
      if(token){
        console.log('有token,正常执行')
        //查询最后一次登录时间信息
          http.get("userinfor/getlastlogin", {params:this.userinfor}) //显示第一页，每页10条数据                
              .then(res=>{
                //console.log(res)
                if(res==null)
                {                    
                  return                  
                }                  
                if(res.data.code==200){
                // console.log(res.data.data);
                  this.userinfor.logindate=res.data.logindate                              
                }                     
              })
              .catch(error=>{
                //console.error(error)
                if(error.code=='401')
                {
                  alert('没有权限，跳转到登录')
                  this.$router.push("/login");
                }            
              })
      }
      //如果token不存在，则由路由守卫来完成跳转
      // else
      // {
      //   console.log('没有token,进行跳转')
      //   //没有信息，跳转到登录页面
      //   alert('没有token，跳转到登录')
      //   //console.log(this.$router)
      //  // this.$router.push("/login");
      // }
    // getData().then((data)=>{
    //    // console.log(data)
    //     this.tableData=data.data
    //     const xAxis=Object.keys(data.data[0]) //这个是枚举出数据的keys
    //     console.log(xAxis);
    //     //基于准备好的dom，初始化echarts实例
    //     const echarts1=echarts.init(this.$refs.echarts1)
    //     var echarts1option={ }
    //     const xAxisData={
    //         data:xAxis
    //     }
    //     echarts1option.xAxis=xAxisData
    //     echarts1option.yAxis={}
    //     echarts1option.legend=xAxisData        
    //     echarts1option.series=[]
    //     xAxis.forEach(key=>{
    //       echarts1option.series.push({
    //         name:key,
    //         data:data.data.map(item=>item[key]),  //重新映射为一个数组
    //         type:'line'
    //       })
    //     }) 
    //     echarts1.setOption(echarts1option)     
    // })
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
.home{
  height:calc(100vh - 120px);
}
.user {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  img {
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .name {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .access {
    color: #999;
  }
}
.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;
    span {
      color: #666666;
      margin-left: 60px;
    }
  }
}
.num{
    display: flex;   //让所有的一行显示，要指定大小
    flex-wrap: wrap;  //强制换行 
    justify-content: space-between;       

    .icon{
        height: 80px;
        width: 80px;font-size: 30px;
        color: #fff;
        text-align: center;
        line-height: 80px;
    }
    .detail{
        display: flex;
        flex-direction: column;   //flex布局方向垂直
        justify-content: center;  //主轴方向居中
        margin-left: 15px;
        .price{
            font-size: 30px;
            margin-bottom: 10px;
            line-height: 30px;
        }
        .desc{
            font-size: 14px;
            color: #999999;
            text-align: center;
            line-height: 30px;            
        }
    }
    .el-card{
        width: 32%;
        margin-bottom: 20px;
    }
}
.graph{
  display: flex;
  justify-content: space-between; //左右对齐
  margin-top: 20px;
  .el-card{
    width:48%;
  }
}
</style>
