<template>

<el-menu  default-active="1-4-1"  class="el-menu-vertical-demo"  @open="handleOpen" 
 @close="handleClose"  :collapse="isCollapse" :unique-opened="true"
background-color="#545c64"      text-color="#fff"      active-text-color="#ffd04b">
<!-- 是否折叠，若折叠则显示后台，否则显示全部 -->
<h3>{{isCollapse ? '后台':userinfor.showname}}</h3>
    <!-- 这是没有子菜单的,index是唯一标识 -->
    <el-menu-item  class="menuitem" @click="clickmenu(item)"  v-for="item in noChildren" :key="item.name" :index="item.name">
      <!-- <i class="el-icon-menu"></i>  显示图标前面el-icon是固定的,``为es6的模版字符串语法,动态数据${}-->
      <i  :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>    
    <!-- 这是有子菜单的 -->
    <el-submenu  v-for="item in hasChildren" :key="item.label" :index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <!-- slot为插槽 -->
        <span  slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group  class="menuitem" v-for="subItem in item.children" key:="subItem.path">
        <!-- 注意事件写在menu item中 -->
            <el-menu-item  @click="clickmenu(subItem)"  :index="subItem.path">{{ subItem.label }}</el-menu-item>        
      </el-menu-item-group>      
    </el-submenu>
  </el-menu>
</template>


<script>
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
export default {
  data() {
    return {
     // isCollapse: false,//是否水平折叠收起菜单，默认为false
     //在计算属性中已定义了，不能重复定义，所以注释掉
     userinfor:{   
            showname:'',     
        },
     menudata:[
       {
         path:'/',
         name:'home',
         label:'首页',
         icon:'s-home',
         url:'Home/Home'
       }, {
            path:'/unitadmin',
            name:'unitadmin',
            label:'用户管理',
            icon:'user',
            url:'sysset/unitadmin'
        }, {
        label:'系统管理',
        icon:'setting',
        children:[
          {
            path:'/unit',
            name:'unit',
            label:'单位设置',
            icon:'video-play',
            url:'sysset/test'
          },{
            path:'/arm',
            name:'arm',
            label:'网关设置',
            icon:'user',
            url:'sysset/arm'
          },{
            path:'/unitsensortype',
            name:'unitsensortype',
            label:'传感器类型',
            icon:'user',
            url:'sysset/unitsensortype'
          },{
            path:'/sensor_typedetail',
            name:'sensor_typedetail',
            label:'小程序分类设置',
            icon:'user',
            url:'sysset/sensor_typedetail'
          },  {
            path:'/sensoricons',
            name:'sensoricons',
            label:'小程序图标设置',
            icon:'user',
            url:'sysset/sensoricons'
          },        
        ]}, 
        {
        label:'基础信息',
        icon:'info',
        children:[
          {
            path:'/groupname',
            name:'groupname',
            label:'分组名称设置',
            icon:'user',
            url:'sysset/groupname'
          },{
            path:'/type',
            name:'type',
            label:'设备类型设置',
            icon:'user',
            url:'sysset/type'
          }
        ]}, {
        label:'网关管理',
        icon:'loading',
        children:[
          {
            path:'/machinesetup',
            name:'machinesetup',
            label:'安装机器',
            icon:'user',
            url:'machineset/machinesetup'
          },{
            path:'/sensorsetup',
            name:'sensorsetup',
            label:'安装传感器',
            icon:'user',
            url:'machineset/sensor'
          },{
            path:'/sensordeitail',
            name:'sensordeitail',
            label:'终端设置',
            icon:'user',
            url:'machineset/sensordeitail'
          },{
            path:'/IRsetup',
            name:'IRsetup',
            label:'配置遥控器',
            icon:'user',
            url:'machineset/IRsetup'
          },{
            path:'/sensoralarm',
            name:'sensoralarm',
            label:'报警规则',
            icon:'user',
            url:'alarm/sensoralarm'
          },{
            path:'/sensoralarmtarget',
            name:'sensoralarmtarget',
            label:'报警响应设置',
            icon:'user',
            url:'alarm/sensoralarmtarget'
          },{
            path:'/sensoraccess',
            name:'sensoraccess',
            label:'权限配置',
            icon:'user',
            url:'machineset/sensoraccess'
          }, {
            path:'/autocheck',
            name:'autocheck',
            label:'自动控制方案',
            icon:'user',
            url:'machineset/autocheck'
          },{
            path:'/autocontrol',
            name:'autocontrol',
            label:'自动控制响应设置',
            icon:'user',
            url:'machineset/autocontrol'
          },
        ]},  {
          label:'公众号管理',
          icon:'chat-dot-square',
          children:[
          {
            path:'/publicmessage',
            name:'publicmessage',
            label:'公众号设置',
            icon:'user',
            url:'publicmessage/publicmessage'
          },{
            path:'/template',
            name:'template',
            label:'模版消息设置',
            icon:'user',
            url:'publicmessage/template'
          },{
            path:'/receive',
            name:'receive',
            label:'接收人员设置',
            icon:'user',
            url:'publicmessage/receive'
          }]
        },{
          label:'实时监测',
          icon:'s-marketing',
          children:[
            {
              path:'/UIP',
              name:'UIP',
              label:'用电监测',
              icon:'user',
              url:'energy/UIP'
            },{
              path:'/environment',
              name:'environment',
              label:'环境监测',
              icon:'user',
              url:'energy/environment'
            },
          ]}, {
          label:'单位能耗统计',
          icon:'s-marketing',
          children:[
            {
              path:'/water',
              name:'water',
              label:'单位用水统计',
              icon:'user',
              url:'energy/water'
            },{
              path:'/kwh',
              name:'kwh',
              label:'单位用电统计',
              icon:'user',
              url:'energy/kwh'
            }, {
              path:'/gas',
              name:'gas',
              label:'单位用气统计',
              icon:'user',
              url:'energy/gas'
            },
          ]},
          
          
          
          
          
          
          {
          label:'门禁管理',
          icon:'house',
          children:[
          {
            path:'/doorsetup',
            name:'doorsetup',
            label:'门禁安装',
            icon:'user',
            url:'door/doorsetup'
          },{
            path:'/dooraccess',
            name:'dooraccess',
            label:'门禁授权-按场所',
            icon:'user',
            url:'door/dooraccess'
          },{
            path:'/dooraccess_man',
            name:'dooraccess_man',
            label:'门禁授权-按人',
            icon:'user',
            url:'door/dooraccess_man'
          },{
            path:'/doorstate',
            name:'doorstate',
            label:'门禁状态',
            icon:'user',
            url:'door/doorstate'
          },{
            path:'/doorrecord',
            name:'doorrecord',
            label:'开门记录',
            icon:'user',
            url:'door/doorrecord'
          },{
            path:'/dooroperation',
            name:'dooroperation',
            label:'操作日志',
            icon:'user',
            url:'door/dooroperation'
          },{
            path:'/dooralarm',
            name:'dooralarm',
            label:'报警规则',
            icon:'user',
            url:'alarm/dooralarm'
          },{
            path:'/dooralarmtarget',
            name:'dooralarmtarget',
            label:'响应设置',
            icon:'user',
            url:'alarm/dooralarmtarget'
          },
        ]},
        // {
        //   label:'智能会议室',
        //   icon:'s-home',
        //   children:[
        //   {
        //     path:'/meet',
        //     name:'meet',
        //     label:'安装会议室',
        //     icon:'user',
        //     url:'meet/meet'
        //   },{
        //     path:'/meetcontrol',
        //     name:'meetcontrol',
        //     label:'配置会议室',
        //     icon:'user',
        //     url:'meet/meetcontrol'
        //   },{
        //     path:'/meetserver',
        //     name:'meetserver',
        //     label:'服务项配置',
        //     icon:'user',
        //     url:'meet/meetserver'
        //   },{
        //     path:'/meetserverbindman',
        //     name:'meetserverbindman',
        //     label:'服务人员配置',
        //     icon:'user',
        //     url:'meet/meetserverbindman'
        //   },{
        //     path:'/meetapplay',
        //     name:'meetapplay',
        //     label:'会议预约申请',
        //     icon:'user',
        //     url:'meet/meetapplay'
        //   },{
        //     path:'/meetadmin',
        //     name:'meetadmin',
        //     label:'会议审批',
        //     icon:'user',
        //     url:'meet/meetadmin'
        //   },
        // ]
        // },
        {
        label:'数据统计',
        icon:'share',
        children:[
          {
            path:'/lineecharts',
            name:'lineecharts',
            label:'折线图',
            icon:'user',
            url:'data/lineecharts'
          },          
        ]},
        {
        label:'安防报警',
        icon:'phone-outline',
        children:[
          {
            path:'/bullyingsetup',
            name:'bullyingsetup',
            label:'霸陵卫士安装',
            icon:'user',
            url:'bullying/bullyingsetup'
          },{
            path:'/bullying_tel',
            name:'bullying_tel',
            label:'霸陵卫士响应配置',
            icon:'user',
            url:'bullying/bullying_tel'
          },{
            path:'/bullying_state',
            name:'bullying_tel',
            label:'霸陵卫士状态',
            icon:'user',
            url:'bullying/bullying_state'
          },{
            path:'/alarm_record',
            name:'alarm_record',
            label:'报警记录',
            icon:'user',
            url:'alarm/alarm_record'
          },{
            path:'/alarm_bossview',
            name:'alarm_bossview',
            label:'门窗报警面板',
            icon:'user',
            url:'alarm/alarm_bossview'
          },          
        ]},{
        label:'大屏看板',
        icon:'s-data',
        children:[
          {
            path:'/roadledbossview',
            name:'roadledbossview',
            label:'路灯管理',
            icon:'user',
            url:'roadled/roadledbossview'
          },{
            path:'/onekeynet',
            name:'onekeynet',
            label:'一键断网',
            icon:'user',
            url:'machine/onekeynet'
          },
          
        ]},
      ],//这是数组的最外侧括号
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
     // console.log(key, keyPath);
    },    
    clickmenu(item){ //点击菜单的事件，需要传值
      //解决连续两次点击同一个菜单出错的情况,三等于号为一致
       if(this.$route.path!==item.path&&!(this.$route.path==='/home'&& item.path==='/')){
        this.$router.push(item.path)
       }
       //检查面包屑数组，没有则增加一个
       this.$store.commit('selectMenu',item) //第一个参数为事件，第二个为参数
    }

  },
  computed:{//通过计算属性来过滤,把menudata的数据转换后输出
     noChildren(){ //如果无子菜单则为假取反为真直接返回
        return this.menudata.filter(item=>!item.children)
     },
     hasChildren(){ //有子菜单的才返回
        return this.menudata.filter(item=>item.children)
     },
     isCollapse(){ //计算属性中定义一个方法返回变量的值
       return this.$store.state.tab.isCollapse
       //return false
     }
  },
  mounted() {
        // const token= Cookie.get('token')    
        // if(token){
        //   this.userinfor.showname=Cookie.get('showname')
        //   //console.log(this.userinfor)
        // }       
     },

};
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu{
    border-right: none;//去掉右侧的一根线
    height:100vh;  /* 高度为100% */
    min-height: 580px;
    h3{
      color: #fff;
      text-align: center;
      line-height: 48px;
      // 字体的大小和字体的粗细
      font-size: 16px;font-weight: 400;     
    }

}
</style>