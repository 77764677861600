import Vue from 'vue'
import App from './App.vue'
import router from './router'      //引入router,只要有index.js就会默认引用不需要写全了
import ElementUI from 'element-ui'            //引入element-ui
import 'element-ui/lib/theme-chalk/index.css'// 引入element-ui的css
import  store from './store'   //引入store下的状态
import Cookie from 'js-cookie'    //引入cookie进行数据的保存 
//import JwtParser from './tools/jwt-parser'//引入jwt解晰
Vue.config.productionTip = false
//Vue.use(JwtParser);
Vue.use(ElementUI);                           //全局注入element-ui

//添加全局前置导航守卫
router.beforeEach((to,from,next)=>{
  //首先判断token是否存在
  //to是想跳转的页面
  //from
  //next 
  const token= Cookie.get('token')
  if(!token && to.name!=='login'){//token不存在说明没有登录，跳到登录页面
     console.log('路由守卫：跳转到登录页')    
     next({name:'login'})
  }else if (token && to.name==='login'){
     console.log('路由守卫：跳转到主页');
     //如果token错误或过期则删除原来的token，则路由守卫自动进行跳转
    next({name:'home'})
  }else{
    next()
  }
})

new Vue({
  router,              //挂载到根节点
  store,               //挂载存储状态到vue
  render: h => h(App)
}).$mount('#app')
